<template>
  <div class="courseslist-wrapper">
    <h3 class="courseslist-wrapper_mobile-title">
      {{ $t('header.my_courses') }}
    </h3>
    <div class="courseslist">
      <div class="courses_list_item courseslist_course" v-for="course in sortingCourses">
        <div class="courses_list_item_desc">
          <div class="courses_list_item_img" style="background-image: none">
            <img :src="course['preview_image']" alt="" style="max-width: 100%;">
          </div>
          <div class="courses_list_item_desc_text mobile_my_courses">
            <div class="courses_list_item_title">{{ course.title }}</div>
            <div class="courses_list_item_bar">
              <div class="courses_list_item_back" :style="{ width: getProgressWidth(course.progress) }"></div>
              <div class="courses_list_item_process" :style="{ color: '#fff' }">
                {{ course.progress < 100 ? $t('In_process') : $t('Completed') }}
              </div>
              <div
                class="courses_list_item_result"
                :class="course.progress > 85 ? 'm-right-' + (100 - course.progress) : ''">{{ course.progress }}%
              </div>
            </div>
            <!-- <div class="courses_list_item_date">
              <div class="my_courses_list_item_start">{{ $t('Start') }}: 22.04.22</div>
              <div class="my_courses_list_item_between">|</div>
              <div class="my_courses_list_item_end">{{ $t('Ending') }}: 30.05.22</div>
            </div> -->
          </div>
        </div>
        <div class="my_courses_list_item_action" v-if="course.progress !== 100">
          <div
            @click="goToCourse(course.slug)"
            class="my_courses_list_item_continue">
            {{ $t('Proceed') }}
            <div class="my_courses_list_item_arrow"></div>
          </div>
          <!-- <div @click.prevent="unsubscribe(course.slug)" class="my_courses_list_item_unsubscribe">
            {{ $t('Unsubscribe') }}
          </div> -->
        </div>
        <a :href="certificateRoot + course.certificate"
          v-if="course.progress === 100 && course.certificate"
          target="_blank"
          class="my_courses_download_certificate">{{ $t('download_certificate') }}</a>
        <div class="my_courses_view_course" v-if="course.progress === 100" @click="goToCourse(course.slug)">
          {{ $t('view_course') }}
        </div>
      </div>
      <MyPagination
        :totalPages="totalPages"
        :total="total"
        :perPage="perPage"
        :currentPage="currentPage"
        @pagechanged="onPageChange"
      />
    </div>
    <div class="my_courses_filter_container">
      <div class="my_courses_filter_label">{{ $t('Sort_and_filter') }}</div>
      <div class="my_courses_status">
        <div
          @click="sortingCoursesStatus = 'active'"
          class="my_courses_status_done"
          :class="{'active': sortingCoursesStatus === 'active'}">
          {{ $t('Active') }}
        </div>
        <div
          @click="sortingCoursesStatus = 'passed'"
          class="my_courses_status_done"
          :class="{'active': sortingCoursesStatus === 'passed'}">
          {{ $t('Passed') }}
        </div>
        <div
          @click="sortingCoursesStatus = 'all'"
          class="my_courses_status_done"
          :class="{'active': sortingCoursesStatus === 'all'}">
          {{ $t('All') }}
        </div>
      </div>
      <div class="my_courses_list_sorting">
        <div class="my_courses_list_sorting_label" @click="showSorting = !showSorting">{{ $t('Sorting') }}</div>
        <div class="my_courses_list_radio_block" v-show="showSorting">
          <div class="my_courses_radio_item active">
            <input type="radio" name="sort" id="newest" class="my_courses_list_radio" checked />
            <label for="newest" class="my_courses_radio_label">{{ $t('By_pass_percentage') }}</label>
          </div>
          <div class="my_courses_radio_item">
            <input type="radio" name="sort" id="popular" class="my_courses_list_radio" />
            <label for="popular" class="my_courses_radio_label">{{ $t('End') }}</label>
          </div>
        </div>
      </div>
      <div class="my_courses_list_sorting">
        <div class="my_courses_list_sorting_label" @click="showCourseDuration = !showCourseDuration">{{ $t('Course_duration') }}</div>
        <div class="my_courses_list_radio_block" v-show="showCourseDuration">
          <div class="my_courses_radio_item active">
            <input type="radio" name="filter" id="8" class="my_courses_list_radio" checked />
            <label for="8" class="my_courses_radio_label">8</label>
          </div>
          <div class="my_courses_radio_item">
            <input type="radio" name="filter" id="36" class="my_courses_list_radio" />
            <label for="36" class="my_courses_radio_label">36</label>
          </div>
          <div class="my_courses_radio_item">
            <input type="radio" name="filter" id="40" class="my_courses_list_radio" />
            <label for="40" class="my_courses_radio_label">40</label>
          </div>
          <div class="my_courses_radio_item">
            <input type="radio" name="filter" id="72" class="my_courses_list_radio" />
            <label for="72" class="my_courses_radio_label">72</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPagination from "@/components/MyPagination.vue";

export default {
  name: 'CoursesHome',
  components: {
    MyPagination,
  },
  data() {
    return {
      certificateRoot: API_ROOT + '/storage/',
      courses: [],
      sortingCourses: [],
      currentPage: 1,
      perPage: 12,
      total: 0,
      totalPages: 0,
      showCourseDuration: true,
      showSorting: true,
      sortingCoursesStatus: '',
    }
  },
  methods: {
    goToCourse(slug) {
      // this.$router.push(`/course/${slug}`)
      this.$router.push({ name: 'about-course', params: { slug } })
    },
    getCourses(page) {
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/courses/my?page=${page}`)
        .then(res => {
          this.courses = res?.body?.data

          this.courses = this.$i18n.locale === 'ru'
            ? this.courses.filter(course => course.language === 'Русский')
            : this.courses.filter(course => course.language === 'Казахский')

          this.sortingCourses = this.courses
          this.total = res?.body?.meta?.total
          this.perPage = res?.body?.meta['per_page']
          this.totalPages = res?.body?.meta['last_page']
          this.sortingCoursesStatus = this.$router.history.current.params.type
          //this.sortingCoursesStatus = 'active'
        })
        .catch(err => console.log(err))
        .finally(() => {

        })
    },
    onPageChange(page) {
      this.currentPage = page;
      this.getCourses(page)
    },
    getProgressWidth(progress) {
      if (progress > 0 && progress < 40)
        progress = 40
      return progress + '%'
    },
  },
  computed: {
    type: function () {
      return this.$route.params.type
    }
  },
  watch: {
    sortingCoursesStatus() {
      if (this.sortingCoursesStatus === 'active') {
        this.sortingCourses = this.courses.filter(course => course.progress < 100)
      } else if (this.sortingCoursesStatus === 'passed') {
        this.sortingCourses = this.courses.filter(course => course.progress === 100)
      } else {
        this.sortingCourses = this.courses
      }
    },
    '$route'(to) {
      this.sortingCoursesStatus = to.params.type
    }
  },
  mounted() {
    this.getCourses(this.currentPage)
  }
}
</script>

<style lang="scss" scoped>
.courseslist-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  justify-content: space-between;
  padding-top: 60px;

  &_mobile-title {
    display: none;
  }
}
.courseslist {
    width: calc(100% - 400px);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    &_course {
        background: white;
        cursor: pointer;
    }
}
.courseslist {
  display: flex;
  flex-wrap: wrap;

  &_course {
    width: 290px;
  }
}
.courses_list_item .courses_list_item_img {
  height: 140px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.courses_list_item .courses_list_item_desc .courses_list_item_desc_text .courses_list_item_title {
  min-height: 48px;
}

.courses_list_item_bar {
  border-radius: 20px;
  padding: 5px;
  padding-left: 20px;
}

.courses_list_item_result {
  background: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  color: #0B1F35;
}

.my_courses_download_certificate {
  background: #007BFC;
  color: #fff;
}

.my_courses_view_course {
  margin-top: 0;
}

.my_courses_view_course, .my_courses_download_certificate {
  padding-left: 30px;
}

.my_courses_view_course::before, .my_courses_download_certificate::before{
  top: 9px
}

.my_courses_status_done.active {
  background: #007BFC;
  color: #fff;
}

@media (max-width: 2000px) {
  .courseslist {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1700px) {
  .courseslist {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .courseslist-wrapper {
    flex-direction: column;
    gap: 20px;

    &_mobile-title {
      display: block;
      font-family: 'RoadRadio';
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .courseslist {
      width: 100%;
    }
    .my_courses_filter_container {
      display: none;
    }
  }
}

@media screen and (max-width: 460px) {
  .courseslist_course {
    width: 100%;
  }
}
</style>
